<template>
  <div class="dashboard">
    <div class="row gy-3 mb-3">
      <div class="col-md-12">
        <h6 class="mb-3">Hi, {{activeUser.first_name}}</h6>
        <h5 class="">Welcome to the dispatcher's dashboard - Central Point</h5>
      </div>
      <div class="col-md-4">
        <div class="card text-center">
          <div class="card-body position-relative">
            <h5 class="text-primary"> <i class="ri ri-file-text-line align-bottom"></i> Total Allocations</h5>
            <p> {{result.allocations.total}} total allocation{{ result.allocations.total > 1 ? 's':''}}</p>
            <router-link class="text-decoration-underline" to="/allocations"> View Allocations </router-link>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card text-center">
          <div class="card-body position-relative">
            <h5 class="text-primary"> <i class="mdi mdi-hospital-building align-bottom"></i> Hospitals</h5>
            <p> {{result.hospitals}} total hospitals</p>
            <router-link class="text-decoration-underline" to="/hospitals"> View Hospitals </router-link>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card text-center">
          <div class="card-body position-relative">
            <h5 class="text-primary"> <i class="ri  ri-account-circle-line align-bottom"></i> Your Account</h5>
            <p>Information & setttings</p>
            <router-link class="text-decoration-underline" to="/account"> Account Settings </router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <statistic-apex-count
          title="ALLOCATIONS IN PROGRESS"
          description="Allocated quantities are not completely confirmed"
          :count-end="result.allocations.in_progress"
          :total="result.allocations.total"
          :figure="result.allocations.in_progress"
          chart-color="#FFB02f"
        />
      </div>
      <div class="col-md-6">
        <statistic-apex-count
          title="DISPATCHED ALLOCATIONS"
          description="Allocated quantities are completely confirmed"
          :count-end="result.allocations.dispatched"
          :total="result.allocations.total"
          :figure="result.allocations.dispatched"
        />
      </div>
    </div>
  </div>
</template>

<script>

import StatisticApexCount from '@/components/widgets/StatisticApexCount.vue';

export default {
    name: 'dashboard',
    components:{
      StatisticApexCount,
    },
    computed:{
      activeUser(){
        return this.$store.state.AppActiveUser
      },
      result(){
        return this.$store.state.dashboardData
      }
    },
}
</script>

